<template>
  <y-layout-body-menu :menu="[
    {title:'基础配置',path:'/card/plugin/middle-man/rule'},
  ]">
    <router-view/>
  </y-layout-body-menu>
</template>

<script>
import YLayoutBodyMenu from "@/components/layouts/YLayoutBodyMenu";

export default {
  name: "index",
  components: {YLayoutBodyMenu},
}
</script>

<style scoped>

</style>